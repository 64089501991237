import React from 'react';
import { Container, Col,Row } from 'react-bootstrap';
import Iframe from 'react-iframe';

function OfficeLocationMap() {
  return (
    <Container fluid> 
        <Row >
            <Col>
            <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d935.2450039726871!2d3.3613059362727276!3d6.593330996777577!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b9240de9b7e29%3A0x6bf80b6a3e779b61!2s19%20Osho%20St%2C%20Opebi%20101233%2C%20Ikeja%2C%20Lagos!5e0!3m2!1sen!2sng!4v1693978754401!5m2!1sen!2sng" width="100%" height="200" frameBorder="0" style={{border:0}} allowfullscreen="" />
            </Col>
        </Row>    
    </Container>
  );
}

export default OfficeLocationMap;
