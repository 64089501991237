import React,  { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'animate.css';
import { Link } from 'react-router-dom'
import haloswb from '../assets/img/Portifolio/web/halosrentals.jpeg'
import ffwb from '../assets/img/Portifolio/web/forcefieldfurnishings.jpeg'
import ifloorl from '../assets/img/b4nafta/ifloorlayout.jpg'
import plaque from '../assets/img/b4nafta/plaque.jpg'
import trophy from '../assets/img/b4nafta/trophy.jpg'
import appmock from '../assets/img/b4nafta/signup.png'
import { Image } from 'react-bootstrap';
//import { Image } from 'react-bootstrap'

export default function Projects() {
  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClose = () => setShow(false);

  const openImageModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setShow(true);
  };

  const backgroundImage = {
    backgroundImage: `url('${haloswb}')`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '225px'
  };
  const backgroundImage1 = {
    backgroundImage: `url('${ffwb}')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '225px'
  };
  const backgroundImage2 = {
    backgroundImage: `url('${ifloorl}')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '225px'
  };
  const backgroundImage3 = {
    backgroundImage: `url('${plaque}')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '225px'
  };
  const backgroundImage4 = {
    backgroundImage: `url('${trophy}')`,
    backgroundSize: 'contain',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '225px'
  };
  const backgroundImage5 = {
    backgroundImage: `url('${appmock}')`,
    backgroundSize: 'contain',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '225px'
  };
  return (
    
<main>

<section className="py-5 text-center container">
  <div className="row py-lg-5">
    <div className="col-lg-6 col-md-8 mx-auto">
      <h1 className="fw-light animate__animated animate__backInLeft">Welcome to Byosworks - Discover Our Projects</h1>
      {/* <p className="lead text-body-secondary">Step into the world of innovation, creativity, and excellence. At Byosworks, we take immense pride in the diverse range of projects that have shaped our journey. Each project is a testament to our passion, expertise, and commitment to delivering exceptional results.</p> */}
      <p className="lead text-body-secondary animate__animated animate__backInRight">Explore our portfolio and dive into a showcase of web and mobile app development, CAD design engineering, print media graphics, and awards production. Whether you're seeking inspiration, insights, or potential collaboration, our projects page offers a glimpse into our dedication to pushing the boundaries of what's possible.</p>
      <p className="lead text-body-secondary animate__animated animate__backInLeft">Join us on this visual journey through our past endeavors and see how Byosworks can help bring your ideas to life.</p>
      <p>
        {/* <Link href="#" className="btn btn-primary my-2">Main call to action</Link>
        <Link href="#" className="btn btn-secondary my-2">Secondary action</Link> */}
      </p>
    </div>
  </div>
</section>

<div className="album py-5 bg-body-tertiary">
  <div className="container">

    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
      <div className="col animate__animated animate__backInLeft">
        <div className="card shadow-sm cover-card">
          <div style={backgroundImage} ></div>
          <div className="card-body">
            <p className="card-text"><b>Halosinsight.com:</b> Crafting digital experiences that shine, one line of code at a time.</p>
            <br/><br/>
            <div className="d-flex justify-content-between align-items-center">
              <div className="btn-group">
                <Button  variant="outline-secondary"  onClick={() => openImageModal(`${haloswb}`)}>View</Button>
                <Link target="_blank" to="https://halosmedia.thehavennation.org/" className="btn btn-primary">Visit</Link>
              </div>
              <small className="text-body-secondary">...</small>
            </div>
          </div>
        </div>
      </div>
      <div className="col animate__animated animate__backInDown">
        <div className="card shadow-sm cover-card">
          <div style={backgroundImage1} ></div>
          <div className="card-body">
            <p className="card-text">We're the force behind <b>ForceFieldFurnishings</b>, crafting digital shields for your furniture needs.</p>
            <br/><br/>
            <div className="d-flex justify-content-between align-items-center">
              <div className="btn-group">
                <Button  variant="outline-secondary"  onClick={() => openImageModal(`${ffwb}`)}>View</Button>
                <Link target="_blank" to="https://forcefieldfurnishings.web.app/" className="btn btn-primary">Visit</Link>
              </div>
              <small className="text-body-secondary">...</small>
            </div>
          </div>
        </div>
      </div>
      <div className="col animate__animated animate__backInRight">
        <div className="card shadow-sm cover-card">
          <div style={backgroundImage5} ></div>
          <div className="card-body">
            <p className="card-text"><b>Mobile App Dev:</b> From pixel-perfect iOS elegance to Android's dynamic versatility, we specialize in crafting seamless mobile experiences that bridge the digital divide.</p>
            <div className="d-flex justify-content-between align-items-center">
              <div className="btn-group">
                <Button  variant="outline-secondary"  onClick={() => openImageModal(`${appmock}`)}>View</Button>
              </div>
              <small className="text-body-secondary">...</small>
            </div>
          </div>
        </div>
      </div>

      
      {/* <div className="col">
        <div className="card shadow-sm cover-card">
          <div style={backgroundImage} ></div>
          <div className="card-body">
            <p className="card-text"><b>Halosinsight.com:</b> Crafting digital experiences that shine, one line of code at a time.</p>
            <div className="d-flex justify-content-between align-items-center">
              <div className="btn-group">
                <button type="button" className="btn btn-sm btn-outline-secondary">View</button>
              </div>
              <small className="text-body-secondary">...</small>
            </div>
          </div>
        </div>
      </div>
      <div className="col animate__animated animate__backInLeft">
        <div className="card shadow-sm cover-card">
          <div style={backgroundImage} ></div>
          <div className="card-body">
            <p className="card-text"><b>Halosinsight.com:</b> Crafting digital experiences that shine, one line of code at a time.</p>
            <div className="d-flex justify-content-between align-items-center">
              <div className="btn-group">
                <button type="button" className="btn btn-sm btn-outline-secondary">View</button>
              </div>
              <small className="text-body-secondary">...</small>
            </div>
          </div>
        </div>
      </div>
      <div className="col animate__animated animate__backInRight">
        <div className="card shadow-sm cover-card">
          <div style={backgroundImage} ></div>
          <div className="card-body">
            <p className="card-text"><b>Halosinsight.com:</b> Crafting digital experiences that shine, one line of code at a time.</p>
            <div className="d-flex justify-content-between align-items-center">
              <div className="btn-group">
                <button type="button" className="btn btn-sm btn-outline-secondary">View</button>
              </div>
              <small className="text-body-secondary">...</small>
            </div>
          </div>
        </div>
      </div> */}

      
      <div className="col animate__animated animate__backInLeft">
        <div className="card shadow-sm cover-card">
          <div style={backgroundImage4} ></div>
          <div className="card-body">
            <p className="card-text"><b>Customized Trophy 3D Design:</b> Our CAD design mastery transforms your vision into tangible success, crafting personalized trophy designs in immersive 3D, tailored to your triumphs.</p>
            <div className="d-flex justify-content-between align-items-center">
              <div className="btn-group">
                <Button  variant="outline-secondary"  onClick={() => openImageModal(`${trophy}`)}>View</Button>
              </div>
              <small className="text-body-secondary">...</small>
            </div>
          </div>
        </div>
      </div>
      <div className="col animate__animated animate__backInUp">
        <div className="card shadow-sm cover-card">
          <div style={backgroundImage3} ></div>
          <div className="card-body">
            <p className="card-text"><b>Customized Plaque 3D Design:</b> Elevate your recognition with bespoke excellence – our CAD design transforms your vision into a stunning, customized plaque in mesmerizing 3D.</p>
            <div className="d-flex justify-content-between align-items-center">
              <div className="btn-group">
                <Button  variant="outline-secondary"  onClick={() => openImageModal(`${plaque}`)}>View</Button>
              </div>
              <small className="text-body-secondary">...</small>
            </div>
          </div>
        </div>
      </div>
      <div className="col animate__animated animate__backInRight">
        <div className="card shadow-sm cover-card">
          <div style={backgroundImage2} ></div>
          <div className="card-body">
            <p className="card-text"><b>Floor Layout Planning:</b> We leverage CAD expertise to bring your space to life with meticulous floor layout planning, ensuring functionality meets aesthetics seamlessly.</p>
            <div className="d-flex justify-content-between align-items-center">
              <div className="btn-group">
                <Button  variant="outline-secondary"  onClick={() => openImageModal(`${ifloorl}`)}>View</Button>
              </div>
              <small className="text-body-secondary">...</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<Modal  
      show={show}
      onHide={handleClose} 
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title>...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {selectedImage && (
            <Image src={selectedImage} alt="Image" className="img-fluid" />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
</main>
  )
}
