import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import AboutUs from './pages/About';
import Projects from './pages/Projects';
import ContactUs from './pages/Contact';
import ScrollToTopButton from './components/ScrollToTopButton'

function App() {
  return (
    <Router>
      <Header />
      <main>
        <div className="container-fliud">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/contact" element={<ContactUs />} />
          </Routes>
        </div>
      </main>
      <Footer />
      <ScrollToTopButton />
    </Router>
  );
}

export default App;
