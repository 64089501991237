import React from 'react'
import { Image, Carousel } from 'react-bootstrap'

import banner1 from '../assets/img//banners/banner-1.jpg'
import banner2 from '../assets/img//banners/banner-2.jpg'
import banner3 from '../assets/img//banners/banner-3.jpg'

export default function ImageCarousel() {
  return (
    <Carousel fluid fade style={{ margin: '1.5rem',}}>
      <Carousel.Item>
      <Image src={banner1} className="d-block w-100" alt="..."  style={{  borderTopLeftRadius: "1.5rem", borderTopRightRadius: "1.5rem", borderBottomLeftRadius: "1.5rem", borderBottomRightRadius: "1.5rem"}}></Image>
        <Carousel.Caption>
          {/* <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <Image src={banner2} className="d-block w-100" alt="..." style={{ borderTopLeftRadius: "1.5rem", borderTopRightRadius: "1.5rem", borderBottomLeftRadius: "1.5rem", borderBottomRightRadius: "1.5rem"}}></Image>
        <Carousel.Caption>
          {/* <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <Image src={banner3} className="d-block w-100" alt="..." style={{ borderTopLeftRadius: "1.5rem", borderTopRightRadius: "1.5rem", borderBottomLeftRadius: "1.5rem", borderBottomRightRadius: "1.5rem"}}></Image>
        <Carousel.Caption>
          {/* <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p> */}
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  )
}

