import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function OfficeDetailsGrid() {
  return (
    <Container>
      <Row>
        <Col xs={12} md={6}>
            <h3>Byosworks</h3>
            <p><b>Head-Office:</b> <br/>19 Osho Street, Opebi-Ikeja, Lagos.</p>
            <p><b>Tel:</b> +2348075174855</p>
            <p><b>Email:</b> info@byosworks.com</p>
            <h4>Opening Times:</h4>
            <ul>
              <li>Monday to Saturday 9am – 5pm</li>
              <li>Public Holidays: 9am – 12noon</li>
              <li>Sanitation Days: 12noon – 5pm</li>
            </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default OfficeDetailsGrid;
