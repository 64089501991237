import React from 'react'
import { Button, Image, NavDropdown , Navbar, Nav, Container} from 'react-bootstrap'
import logo from '../assets/img/bwlogo.png'

export default function Header() {
  const handleEmailButtonClick = () => {
    const recipient = 'info@byosworks.com'; // Replace with the recipient's email address
    const subject = 'Byosworks - Service Enquiries'; // Replace with the email subject
    const body = 'Good Day, I/We like to make enquiry about ...'; // Replace with the email body

    // Create the mailto link
    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    // Open the link in a new tab/window
    window.open(mailtoLink);
  };

  return (
    <Navbar expand="lg" className="bg-body-tertiary" sticky="top">
      <Container fluid>
        <Navbar.Brand href="#home"><Image className='animate__animated animate__backInLeft' src={logo} alt="Byosworks Inc." style={{width: "200px", height: "auto"}} ></Image></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto justify-content-end">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/about">About Us</Nav.Link>
            <NavDropdown title="Our Services" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1"><i className="bi bi-arrow-return-right"></i> Web Design & Development</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
              <i className="bi bi-arrow-return-right"></i> Mobile App Design & Development (Android/iOS/Windows)
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.3"><i className="bi bi-arrow-return-right"></i> CAD Design Engineering</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.4"><i className="bi bi-arrow-return-right"></i> CAD: Design For Assembly</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.5"><i className="bi bi-arrow-return-right"></i> CAD:  Design For Manufacture</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.6"><i className="bi bi-arrow-return-right"></i> Award Customization & Production</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.7">
                <i className="bi bi-arrow-return-right"></i> Graphic Design
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.8">
                <i className="bi bi-arrow-return-right"></i> Print Media
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/projects">Projects</Nav.Link>
            <Nav.Link href="/contact">Contact Us</Nav.Link>
            </Nav>
            <Nav  className="d-flex">
                <Button variant="primary" style={{marginRight: '0.5rem'}}><i className="bi bi-telephone-inbound-fill"  to='tel:2348075174855'></i></Button>{' '}
                <Button variant="success" style={{marginRight: '0.5rem'}} id="whatsappButton" to="#" target="_blank"  onClick={event => sendWhatsAppMessage()}><i className="bi bi-whatsapp"></i></Button>{' '}
                <Button variant="warning" style={{marginRight: '0.5rem'}}  onClick={handleEmailButtonClick}><i className="bi bi-envelope-at-fill"></i></Button>{' '}
            </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}


 
function sendWhatsAppMessage() {
  var phoneNumber = "2348075174855";
  var customMessage = "Welcome to Byosworks! we are a specialized firm offering Web & App Development, Software Engineering, CAD Design Engineering, and Graphic Design services. With a skilled team of professionals, they create cutting-edge websites and applications, tailored software solutions, and detailed CAD models. Their graphic design expertise provides captivating visuals and branding materials. We prioritizes quality, timely delivery, and customer satisfaction, making them the go-to choice for diverse business needs. Contact them today to bring your ideas to life.";
  
  // Replace any spaces in the phone number with an empty string
  phoneNumber = phoneNumber.replace(/\s/g, '');
  
  // Check if the custom message is provided
  var messageParam = customMessage ? "&text=" + encodeURIComponent(customMessage) : "";
  
  // Open WhatsApp with the number and custom message (if provided)
  window.open("https://api.whatsapp.com/send?phone=" + phoneNumber + messageParam );
}