import React from 'react'
import { Button, ButtonGroup, Image , Container, Row, Col } from 'react-bootstrap'
import logo from '../assets/img/bwlogo.png'

export default function Footer() {
    const currentYear = new Date().getFullYear();
    const handleEmailButtonClick = () => {
      const recipient = 'info@byosworks.com'; // Replace with the recipient's email address
      const subject = 'Byosworks - Service Enquiries'; // Replace with the email subject
      const body = 'Good Day, I/We like to make enquiry about ...'; // Replace with the email body
  
      // Create the mailto link
      const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  
      // Open the link in a new tab/window
      window.open(mailtoLink);
    };const handleFB = () => {
      const linkUrl = 'https://web.facebook.com/people/Byosworks/100068040103683/'; // Replace with your desired link URL
  
      // Open the link in a new tab/window
      window.open(linkUrl, '_blank');
    };
    const handleX = () => {
      const linkUrl = 'https://twitter.com/ByosIT'; // Replace with your desired link URL
  
      // Open the link in a new tab/window
      window.open(linkUrl, '_blank');
    };
    const handleIG = () => {
      const linkUrl = 'https://www.instagram.com/byosworks/'; // Replace with your desired link URL
  
      // Open the link in a new tab/window
      window.open(linkUrl, '_blank');
    };
  return (
    <footer className="bg-light text-dark py-3">
      <Container>
        <Row>
          <Col>
            <p><Image src={logo} alt="Byosworks Inc." style={{width: "150px", height: "auto"}} ></Image>{/* <Badge bg="warning"></Badge> */}</p>
            <p><small><b>Address:</b> 19 Osho Street, Opebi, Lagos,<br/> Nigeria</small></p>
            <p><small><b>Email:</b> <a href='/#'   onClick={handleEmailButtonClick}>info@byosworks.com</a></small></p>
          </Col>
          <Col>
          <h5>About Us:</h5>
            <small>We're the architects of digital dreams, crafting excellence in premium web and mobile app development. With a flair for CAD design engineering and a passion for print-to-media graphic design, we're your creative and technical partners.</small>
          </Col>
          <Col>
            <h5>Links</h5>
            <ul className="list-unstyled">
              <li><a href="/" className='text-dark text-decoration-none'>Home</a></li>
              <li><a href="/about" className='text-dark text-decoration-none'>About</a></li>
              <li><a href="/projects" className='text-dark text-decoration-none'>Services</a></li>
              <li><a href="/contact" className='text-dark text-decoration-none'>Contact</a></li>
            </ul>
          </Col>
          <Col>
            <h5>Social Media</h5>
            <ButtonGroup aria-label="Basic example">
              <Button variant="primary" onClick={handleFB}><i className="bi bi-facebook"></i></Button>
              <Button variant="info" onClick={handleX}><i className="bi bi-twitter"></i></Button>
              <Button variant="danger" onClick={handleIG}><i className="bi bi-instagram"></i></Button>
            </ButtonGroup>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
            <p><small>&copy; 2009 - {currentYear}  Byosworks Inc. All rights reserved.</small></p>
        </Row>
      </Container>
    </footer>
  )
}
