import React from 'react';
import { Container, Row, Col, Card, Stack } from 'react-bootstrap';
import OfficeLocationMap from '../components/OfficeLocationMap';
import OfficeDetailsGrid from '../components/OfficeDetailsGrid';
import ContactForm from '../components/ContactForm';
import SocialMediaTiles from '../components/SocialMediaTiles';
import Overlay from '../components/Overlay';
import bg from '../assets/img/pages/bg.jpg'
import '../App.css'; // Import main application styles

function Contact() {
  return (
        <div className='app'>
    <img src={bg} alt="Background" className="background-image" />
    <Overlay />
      <Container fluid>
        <Stack gap={3}>
        <div className="p-2">
        <header class="pb-1 mb-4 border-bottom">
            <span class="fs-4">Contact Us</span>
        </header>
        <p>Kindly, use the contact form below to leave a message</p>
        </div>
        <div className="p-2">
          <Card class="shadow-sm p-3 mb-5 bg-body rounded">
            <Card.Text>
              <OfficeLocationMap />
            </Card.Text>
          </Card>
        
          </div>
        <div className="p-2">
        <Container>
        <Row>
          <Col>
            <OfficeDetailsGrid />
          </Col>
          <Col>
            <ContactForm />
          </Col>
        </Row>
        </Container>
              
          
        </div>
        <div className="p-2">
              <SocialMediaTiles />
        
        </div>

        </Stack>
      </Container>
    </div>
  );
}

export default Contact;
