import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function SocialMediaTiles() {
  return (
    <Container>
      <Row>
        <Col md={4}>
          {/* Social Media Tile 1 */}
        </Col>
        <Col md={4}>
          {/* Social Media Tile 2 */}
        </Col>
        <Col md={4}>
          {/* Social Media Tile 3 */}
        </Col>
      </Row>
    </Container>
  );
}

export default SocialMediaTiles;
