import React, { useState } from 'react';
import { Container, Form, Button, Row, Col, Toast } from 'react-bootstrap';
import axios from 'axios';

const API_ENDPOINT = 'https://halosmedia.thehavennation.org/send_bw';

function ContactForm() {
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    subject: '',
    message: '',
  });
  
  const [showToast, setShowToast] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  /* const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(API_ENDPOINT, formData);
      
      // Show toast on success
      if (response.status === 200) {
        setShowToast(true);
      } else {
        console.error('Failed to send email.');
      }
    } catch (error) {
      // Handle error here
      console.error('Error submitting form:', error);
    }
  }; */

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post(API_ENDPOINT, formData)
      .then((response) => {
        // Handle success
        console.log(response.data);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  return (
    <Container>
      <Row>
        <Col>
            <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="fullname">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                type="text"
                name="fullname"
                value={formData.fullname}
                onChange={handleInputChange}
                required
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="subject">
                <Form.Label>Subject</Form.Label>
                <Form.Control
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleInputChange}
                required
                />
            </Form.Group>

            <Form.Group  className="mb-3" controlId="message">
                <Form.Label>Message</Form.Label>
                <Form.Control
                as="textarea"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                rows={4}
                required
                />
            </Form.Group>

            <Button variant="primary" type="submit">
                Submit
            </Button>
            </Form>

          <Toast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={3000}
            autohide
            style={{
              position: 'absolute',
              bottom: 20,
              right: 20,
            }}
          >
            <Toast.Header>
              <strong className="me-auto">Success</strong>
            </Toast.Header>
            <Toast.Body>Email sent successfully!</Toast.Body>
          </Toast>
        </Col>
      </Row>
    </Container>
  );
}

export default ContactForm;
