import React from 'react'
import Carousel from '../components/ImageCarousel'
import { Stack, Image, Col, Card, Container, Row, Tab , Tabs, Button } from 'react-bootstrap';
import 'animate.css';
import './Home.css'

import spotlight1 from '../assets/img//spotlight/spot-1.jpg'
import spotlight2 from '../assets/img//spotlight/spot-2.jpg'
import spotlight3 from '../assets/img//spotlight/spot-3.jpg'
import tiles1 from '../assets/img//tiles/tile-1.jpg'
import tiles2 from '../assets/img//tiles/tile-4.jpg'
import tiles3 from '../assets/img//tiles/tile-3.jpg'
import tilesg from '../assets/img/hmcards/wdg1.jpeg'
import tilesmsm from '../assets/img/hmcards/wdg2.jpeg'
import tilespm from '../assets/img/hmcards/wdg3.jpeg'
import card41 from '../assets/img/hmcards/awdp-1.jpg'
import card42 from '../assets/img/hmcards/awdp-2.jpg'
import card43 from '../assets/img/hmcards/awdp-3.jpg'
import { Link } from 'react-router-dom';

const haloshr = {  
  border: 0,
  height: "1px",
  backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0))`,
};

export default function Home() {
  return (
    <><hr style={haloshr} />
    <Carousel />
    <hr style={haloshr}/>
    <Stack gap={3}>
    <Container className='marketing'>
      <Row>
      <Col lg={4} xs={6} md={4} className='text-center'>
        <Image className='animate__animated animate__bounce' src={spotlight1}  style={{ width: '10rem' }} alt="..." roundedCircle ></Image>
        <h2 className="fw-normal animate__animated animate__backInUp">Web Design & Development</h2>
        <p className='animate__animated animate__backInUp'>We crafts captivating digital experiences through expert Web Design & Development, blending innovative design with seamless functionality. With a keen eye for user-centered design, we create websites that engage, inform, and inspire, catering to diverse client needs with precision and creativity. Elevate your online presence with our tailored solutions, where every pixel tells a story of impeccable craftsmanship.</p>
        <p className='animate__animated animate__backInUp'><Link className="btn btn-secondary" to="/#">View details &raquo;</Link></p>
      </Col>
      <Col lg={4} xs={6} md={4} className='text-center'>
        <Image className='animate__animated animate__bounce' src={spotlight2}   style={{ width: '10rem' }}  alt="..." roundedCircle ></Image>
        <h2 className="fw-normal animate__animated animate__backInUp">Mobile App Design & Development</h2>
        <p className='animate__animated animate__backInUp'>We pioneers Mobile App Design & Development, sculpting intuitive interfaces and robust functionalities that redefine user engagement. With a focus on user-centric design, we transform concepts into captivating apps that seamlessly integrate innovation and usability. Elevate your brand's mobile experience with us, where every tap unlocks a world of exceptional digital craftsmanship.</p>
        <p className='animate__animated animate__backInUp'><Link className="btn btn-secondary" to="/#">View details &raquo;</Link></p>
      </Col>
      <Col lg={4} xs={6} md={4} className='text-center'>
        <Image className='animate__animated animate__bounce' src={spotlight3}  style={{ width: '10rem' }}  alt="..." roundedCircle ></Image>
        <h2 className="fw-normal animate__animated animate__backInUp">CAD Design Engineering</h2>
        <p className='animate__animated animate__backInUp'>We excels in CAD Design Engineering, sculpting intricate blueprints into tangible innovation. Their meticulous approach merges creativity with precision, delivering designs that push boundaries and streamline production. Elevate your projects with our expertise, where every detail is meticulously crafted to shape a future of unparalleled engineering excellence.</p>
        <p><Link className="btn btn-secondary" to="/#">View details &raquo;</Link></p>
      </Col>
      </Row>
    </Container>
    <hr style={haloshr}/>
    <Container>
      <Row>
    <Tabs
      defaultActiveKey="profile"
      id="justify-tab-example"
      className="mb-3"
      justify
    >
      <Tab eventKey="home" title="Development">
      <Row>
      <Col lg={4} xs={6} md={4} className='text-center'>
        <Card style={{ width: '100%' }} class="shadow-sm p-3 mb-5 bg-body rounded">
          <Card.Img variant="top" src={tiles1} />
          <Card.Body>
            <Card.Title>Web Application</Card.Title>
            <Card.Text>
            Empower your online presence with our expertise, as we build web applications that redefine user experiences through innovation and precision.
            </Card.Text>
            <Button variant="primary"><i className="bi bi-arrow-return-right"></i> Learn More</Button>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={4} xs={6} md={4} className='text-center'>
        <Card style={{ width: '100%' }} class="shadow-sm p-3 mb-5 bg-body rounded">
          <Card.Img variant="top" src={tiles2} />
          <Card.Body>
            <Card.Title>Mobile Application (Android & iOS) </Card.Title>
            <Card.Text>
            Unlock the potential of mobility with us, as we engineer mobile applications that deliver convenience & brilliance in every tap.
            </Card.Text>
            <Button variant="primary"><i className="bi bi-arrow-return-right"></i> Learn More</Button>
          </Card.Body>
        </Card>
        </Col>
      <Col lg={4} xs={6} md={4} className='text-center'>
        <Card style={{ width: '100%' }} class="shadow-sm p-3 mb-5 bg-body rounded">
          <Card.Img variant="top" src={tiles3} />
          <Card.Body>
            <Card.Title>UI/UX web designs</Card.Title>
            <Card.Text>
              We weaves digital artistry, sculpting UI/UX web designs that blend aesthetic allure with seamless user journeys.
            </Card.Text>
            <Button variant="primary"><i className="bi bi-arrow-return-right"></i> Learn More</Button>
          </Card.Body>
        </Card>
        </Col>
        </Row>
      </Tab>
      <Tab eventKey="profile" title="Design Engineering">
      <Row>
      <Col lg={4} xs={6} md={4} className='text-center'>
        <Card style={{ width: '100%' }}>
          <Card.Img variant="top" src={card41} />
          <Card.Body>
            <Card.Title>Medals</Card.Title>
            <Card.Text>
            Elevate accomplishments with our masterful touch, turning moments into beautifully crafted medals and awards.
            </Card.Text>
            <Button variant="primary">Learn More</Button>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={4} xs={6} md={4} className='text-center'>
        <Card style={{ width: '100%' }}>
          <Card.Img variant="top" src={card42} />
          <Card.Body>
            <Card.Title>Plaques</Card.Title>
            <Card.Text>
            Crafting distinction, we creates plaques that capture milestones in exquisite detail, celebrating accomplishments with a touch of sophistication.
            </Card.Text>
            <Button variant="primary">Learn More</Button>
          </Card.Body>
        </Card>
        </Col>
      <Col lg={4} xs={6} md={4} className='text-center'>
        <Card style={{ width: '100%' }}>
          <Card.Img variant="top" src={card43} />
          <Card.Body>
            <Card.Title>Trophies</Card.Title>
            <Card.Text>
            Elevate success with our meticulous design, crafting trophies that stand as symbols of excellence and dedication.
            </Card.Text>
            <Button variant="primary">Learn More</Button>
          </Card.Body>
        </Card>
        </Col>
        </Row>
      </Tab>
      <Tab eventKey="longer-tab" title="Graphics">
      <Row>
      <Col lg={4} xs={6} md={4} className='text-center'>
        <Card style={{ width: '100%' }}>
          <Card.Img variant="top" src={tilesg} />
          <Card.Body>
            <Card.Title>Web Graphics Design</Card.Title>
            <Card.Text>
            Elevate your online presence with our web graphics design expertise. Our creative flair transforms websites into visual masterpieces, leaving a lasting impression on your audience. Let us bring your digital vision to life
            </Card.Text>
            <Button variant="primary"><i className="bi bi-arrow-return-right"></i> Learn More</Button>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={4} xs={6} md={4} className='text-center'>
        <Card style={{ width: '100%' }}>
          <Card.Img variant="top" src={tilesmsm} />
          <Card.Body>
            <Card.Title>Media to Socio-Media</Card.Title>
            <Card.Text>
            From traditional media to the ever-evolving world of social, we designs captivating visuals that engage and resonate. Our expertise transforms content into shareable, memorable experiences, ensuring your brand stands out in the digital crowd. Harness the power of compelling graphics with us.
            </Card.Text>
            <Button variant="primary"><i className="bi bi-arrow-return-right"></i> Learn More</Button>
          </Card.Body>
        </Card>
        </Col>
      <Col lg={4} xs={6} md={4} className='text-center'>
        <Card style={{ width: '100%' }}>
          <Card.Img variant="top" src={tilespm} />
          <Card.Body>
            <Card.Title>Print Media</Card.Title>
            <Card.Text>
            Experience the artistry of print with Byosworks. Our meticulous approach and creative mastery bring your print projects to life, leaving a lasting impact. Elevate your brand through captivating, high-quality print media graphics.
            </Card.Text>
            <Button variant="primary"><i className="bi bi-arrow-return-right"></i> Learn More</Button>
          </Card.Body>
        </Card>
        </Col>
        </Row>
      </Tab>
      <Tab eventKey="contact" title="Awards Production" >
      <Row>
      <Col lg={4} xs={6} md={4} className='text-center'>
        <Card style={{ width: '100%' }}>
          <Card.Img variant="top" src={card41} />
          <Card.Body>
            <Card.Title>Medals</Card.Title>
            <Card.Text>
            "We crafts excellence into art, sculpting medals and awards that immortalize achievements in brilliance. Elevate accomplishments with us a masterful touch, turning moments into beautifully crafted medals and awards."
            </Card.Text>
            <Button variant="primary">Learn More</Button>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={4} xs={6} md={4} className='text-center'>
        <Card style={{ width: '100%' }}>
          <Card.Img variant="top" src={card42} />
          <Card.Body>
            <Card.Title>Plaques</Card.Title>
            <Card.Text>
            "We transforms recognition into lasting elegance, molding plaques that honor achievements with timeless grace. Crafting distinction, we creates plaques that capture milestones in exquisite detail, celebrating accomplishments with a touch of sophistication."
            </Card.Text>
            <Button variant="primary"><i className="bi bi-arrow-return-right"></i> Learn More</Button>
          </Card.Body>
        </Card>
        </Col>
      <Col lg={4} xs={6} md={4} className='text-center'>
        <Card style={{ width: '100%' }}>
          <Card.Img variant="top" src={card43} />
          <Card.Body>
            <Card.Title>Trophies</Card.Title>
            <Card.Text>
            "We sculpts victory into art, fashioning trophies that embody triumph with unparalleled craftsmanship. Elevate success with us meticulous design, crafting trophies that stand as symbols of excellence and dedication."
            </Card.Text>
            <Button variant="primary">Learn More</Button>
          </Card.Body>
        </Card>
        </Col>
        </Row>
      </Tab>
    </Tabs>
      </Row>
    </Container>
    </Stack>
    <hr style={haloshr}/>
    </>
  )
}
