import React from 'react';
import {Stack, Image} from 'react-bootstrap';
import Overlay from '../components/Overlay';
import bg from '../assets/img/pages/bg.jpg'
import about from '../assets/img/about/bwabt2.jpg'
import '../App.css'; // Import main application styles

function AboutUs() {
  const haloshr = {  
    border: 0,
    height: "1px",
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0))`,
  };
  return (
  <div className='app'>
  <img src={bg} alt="Background" className="background-image" />
  <Overlay />
  <Stack gap={3}>
    <div className="p-2">
    <header class="pb-3 mb-4 border-bottom">
        <span class="fs-4">About Us</span>
    </header>
    </div>
    <div className="p-2">
      <div class="p-5 mb-4 bg-body-dark rounded-3">
        
        <div class="container-fluid py-5">
          <h1 class="display-5 fw-bold">At Byosworks:</h1>
          <p class="col-md-8 fs-4"> we're the architects of digital dreams, crafting excellence in premium web and mobile app development. With a flair for CAD design engineering and a passion for print-to-media graphic design, we're your creative and technical partners.</p>
          {/* <button class="btn btn-primary btn-lg" type="button">Learn More</button> */}
        </div>
      </div>
    </div>
        <hr style={haloshr} />
    <div className="p-2">
    <div class="b-example-divider"></div>
      <div class="container col-xxl-8 px-4 py-5">
        <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div class="col-10 col-sm-8 col-lg-6">
            <Image src={about} class="d-block mx-lg-auto img-fluid" alt="..." width="700" height="500" loading="lazy"></Image>
          </div>
          <div class="col-lg-6">
            <h1 class="display-5 fw-bold text-body-emphasis lh-1 mb-3">Our Journey:</h1>
            <p class="lead"> With each project, we've learned, grown, and adapted, refining our craft and pushing the boundaries of creativity and technology. Our journey is a testament to our unwavering dedication to perfection, where every milestone reached is a step closer to achieving our vision.</p>
            {/* <div class="d-grid gap-2 d-md-flex justify-content-md-start">
              <button type="button" class="btn btn-primary btn-lg px-4 me-md-2">Primary</button>
              <button type="button" class="btn btn-outline-secondary btn-lg px-4">Default</button>
            </div> */}
          </div>
        </div>
  </div>

    </div>
        <hr style={haloshr} />
    <div className="p-5">
        <div class="row align-items-md-stretch">
          <div class="col-md-6">
            <div class="h-100 p-5 text-bg-warning rounded-3">
              <h2>VISION</h2>
              <p>Our vision at Byosworks is to seamlessly merge cutting-edge technology with artistic innovation. We aspire to be the go-to hub for premium digital solutions, where creativity knows no bounds, and excellence is our only standard.</p>
              
            </div>
          </div>
          <div class="col-md-6">
            <div class="h-100 p-5 bg-body-secondary rounded-3">
              <h2>MISSION</h2>
              <p>Byosworks is on a mission to redefine the digital landscape. We're committed to delivering top-tier web and mobile app development, CAD design engineering, and print-to-media graphic design. Our dedication to perfection ensures your success, and our passion drives your vision forward. Together, we create excellence.</p>
                <ul>
                  <li>Provide high-quality,affordable contract solution</li>
                  <li>Create and cultivate long-term relationship with clients</li>
                  <li>Responding immediately to the changing needs of our society</li>
                  <li>Achieving complete customer satisfaction</li>
                  <li>Improve our services continuously and achieve the highest standard of performance.</li>
                </ul>
            </div>
          </div>
        </div>
    </div>
  </Stack>
  </div>
  );
}

export default AboutUs;
